import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { H3 } from "app/components/Typography";
import convertTimeToString from "helpers/convertTimeToString";
import { useHistory, useLocation } from "react-router";
import { useContentSelectClassEvent } from "hooks/analytics";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { AccessType, AchievementStudioClassFragment } from "services/graphql";
import { ExplicitBadge } from "app/components/ClassCard/Card/components";
import {
  ThumbnailContainer,
  ThumbnailWrapper,
  ClassInfoText,
  LockedLabel,
  CompletedIcon as BaseCompletedIcon,
} from "app/routes/Playlists/PlaylistClassRow/styles";
import { ClassInfoLabel } from "app/routes/Playlists/PlaylistClassRow/ClassInfoLabel";
import { useCanUserTakeClass } from "hooks/Classes";

const StyledLinkWrapper = styled.a<{ width?: string }>`
  width: ${({ width }) => width || "auto"};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
`;

const CompletedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompletedIcon = styled(BaseCompletedIcon)`
  color: ${({ theme }) => theme.colors.bulbaGreen};
  position: static;
  width: 24px;
  height: 24px;
  transform: none;
`;

interface Props {
  studioClass: AchievementStudioClassFragment;
}

export function AchievementStudioClassRow({ studioClass }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData: studioClass,
  });
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: studioClass.classId,
  });

  const classUrl = `/class/${studioClass.classId}`;

  const goToClass = () => {
    contentSelectClassEvent({
      location: getAnalyticsLocationFromPath(location.pathname),
      selected_from: "Challenge Required Classes",
    });

    history.push(classUrl, {
      previousLocation: location,
    });
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    goToClass();
  };

  return (
    <Flex width="100%" justifyContent="space-between" maxWidth="768px">
      <Flex gap="16px" flexWrap="wrap">
        <StyledLinkWrapper onClick={handleClick} href={classUrl} width="150px">
          <ThumbnailContainer>
            <ThumbnailWrapper>
              <Thumbnail
                src={`${studioClass.thumbnailImageUrl}&w=600`}
                placeholderSrc={`${studioClass.thumbnailImageUrl}&blur=200`}
              />
              <Flex
                position="absolute"
                top="12px"
                left="12px"
                alignItems="center"
                gap="5px"
              >
                {!canUserTakeClass &&
                  studioClass.accessType !== AccessType.Free && <LockedLabel />}
              </Flex>
              {studioClass.userProgress?.completed && (
                <CompletedOverlay>
                  <CompletedIcon />
                </CompletedOverlay>
              )}
            </ThumbnailWrapper>
          </ThumbnailContainer>
        </StyledLinkWrapper>
        <Flex flexDirection="column" flex="1">
          <StyledLinkWrapper onClick={handleClick} href={classUrl}>
            <H3 mb="12px" limitLines={2}>
              {studioClass.title}
            </H3>
          </StyledLinkWrapper>
          <Flex gap="8px">
            {studioClass.level && <ClassInfoLabel label={studioClass.level} />}
            {studioClass.video && (
              <ClassInfoLabel
                label={convertTimeToString(
                  Number(studioClass.video.durationInMs) / 1000,
                  { discrete: true, showSeconds: false }
                )}
              />
            )}
            <ClassInfoLabel label={studioClass.type} />
          </Flex>
          <Flex gap="8px" alignItems="center">
            <ClassInfoText>{studioClass.instructor?.name}</ClassInfoText>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
