import { AchievementTriggerType } from "services/graphql";

export function getDaysLeft(endTime: string): number {
  const endDate = new Date(endTime);
  const currentDate = new Date();
  const timeDifference = endDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}

export function getProgressPercentage(
  isCompleted: boolean,
  currentBadgeProgress: number,
  nextBadgeAmountToEarn: number
): number {
  if (isCompleted) {
    return 1;
  }
  if (nextBadgeAmountToEarn === 0) {
    return 0;
  }
  return currentBadgeProgress / nextBadgeAmountToEarn;
}

export function getBadgeUnit(triggerType: string) {
  switch (triggerType) {
    case AchievementTriggerType.ClassCompletion:
    case AchievementTriggerType.RequiredClassCompletion:
    case AchievementTriggerType.RequiredClassTaken:
    case AchievementTriggerType.ClassTaken:
      return "classes";
    case AchievementTriggerType.ClassMinutesSpent:
      return "minutes spent";
    case AchievementTriggerType.PostComment:
      return "comments";
    case AchievementTriggerType.PostUpload:
      return "posts";
    default:
      return "";
  }
}
